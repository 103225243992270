import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homepage',
    meta: { title: 'Memefarm' },
    component: () => import(/* webpackChunkName: "about" */ '../views/homepage/index.vue')
  },

]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {

  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
